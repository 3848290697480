<template>
  <!--begin::Charts Widget 3-->
  <div :class="widgetClasses" class="card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Recent Transactions</span>

        <span class="text-muted fw-bold fs-7"
          >Block registration overview
        </span>
      </h3>

      <!--begin::Toolbar-->
      <!-- <div class="card-toolbar" data-kt-buttons="true">
        <a
          class="btn btn-sm btn-color-muted btn-active btn-active-primary disabled px-4 me-1"
          id="kt_charts_widget_3_year_btn"
          >Year</a
        >

        <a
          class="btn btn-sm btn-color-muted btn-active btn-active-primary active px-4 me-1"
          id="kt_charts_widget_3_month_btn"
          >Month</a
        >

        <a
          class="btn btn-sm btn-color-muted btn-active btn-active-primary disabled px-4"
          id="kt_charts_widget_3_week_btn"
          >Week</a
        >
      </div> -->
      <!--end::Toolbar-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Chart-->
      <apexchart
        type="area"
        height="400"
        :options="options"
        :series="series.series"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Charts Widget 3-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "widget-1",
  emits: ["updateChart"],
  props: {
    data: Object,
    widgetClasses: String
  },
  components: {},
  computed: {
    options() {
      const labelColor = getCSSVariableValue("--bs-gray-500");
      const borderColor = getCSSVariableValue("--bs-gray-200");
      const baseColor = getCSSVariableValue("--bs-info");
      const lightColor = getCSSVariableValue("--bs-light-info");

      const cat = this.data?.options.xaxis.categories;

      return {
        chart: {
          fontFamily: "inherit",
          type: "area",
          height: 450,
          toolbar: {
            show: false
          }
        },
        plotOptions: {},
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: "solid",
          opacity: 1
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
          colors: [baseColor]
        },
        xaxis: {
          categories: cat,
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px"
            }
          },
          crosshairs: {
            position: "front",
            stroke: {
              color: baseColor,
              width: 1,
              dashArray: 3
            }
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px"
            }
          }
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none",
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        noData: {
          text: "Loading..."
        },
        tooltip: {
          style: {
            fontSize: "12px"
          },
          y: {
            formatter: function(val) {
              return val;
            }
          }
        },
        colors: [lightColor],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          strokeColor: baseColor,
          strokeWidth: 3
        }
      };
    },
    series() {
      const res = this.data?.series[0].data;
      return {
        series: [
          {
            name: "New Blocks",
            data: res
          }
        ]
      };
    }
  },
  mounted() {
    const delay = 600;
    setTimeout(() => {
      this.$emit("updateChart");
    }, delay);
  }
});
</script>
