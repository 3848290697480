import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Chart from "./ChartWidget.vue";
import store from "@/store";
export default defineComponent({
    name: "stats",
    components: {
        Chart: Chart
    },
    setup: function () {
        var page = "1";
        localStorage.setItem("currentPage", page);
        var currentPage = localStorage.getItem("currentPage");
        store.dispatch("fetchStats", currentPage);
        onMounted(function () {
            setCurrentPageTitle("Stats");
        });
    },
    created: function () {
        var _this = this;
        var delay = 600;
        setTimeout(function () {
            var statsInfo = store.getters.statsInfo;
            var dates = _this.getDatesReversed(statsInfo);
            var blocks = _this.getNumbersOfBlocksReversed(statsInfo);
            _this.chartData.options.xaxis.categories = dates;
            _this.chartData.series[0].data = blocks;
        }, delay);
    },
    data: function () {
        var options = {
            chart: {
                fontFamily: "inherit",
                type: "area",
                height: 450,
                toolbar: {
                    show: false
                }
            },
            xaxis: {
                categories: [""]
            },
            noData: {
                text: "Loading..."
            }
        };
        var series = [
            {
                name: "New Blocks",
                data: [0, 0, 0, 0, 0, 0, 0]
            }
        ];
        return {
            chartData: {
                options: options,
                series: series
            }
        };
    },
    methods: {
        updateValues: function () {
            //
        },
        getDatesReversed: function (dataObj) {
            var res = [];
            for (var i in dataObj) {
                var item = dataObj[i].new_date;
                // console.log(item);
                res.push(item);
            }
            var dates = res.reverse();
            return dates;
        },
        getNumbersOfBlocksReversed: function (dataObj) {
            var res = [];
            for (var i in dataObj) {
                var item = dataObj[i].blocks;
                // console.log(item);
                res.push(item);
            }
            var blocks = res.reverse();
            return blocks;
        }
    }
});
