import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
export default defineComponent({
    name: "widget-1",
    emits: ["updateChart"],
    props: {
        data: Object,
        widgetClasses: String
    },
    components: {},
    computed: {
        options: function () {
            var _a;
            var labelColor = getCSSVariableValue("--bs-gray-500");
            var borderColor = getCSSVariableValue("--bs-gray-200");
            var baseColor = getCSSVariableValue("--bs-info");
            var lightColor = getCSSVariableValue("--bs-light-info");
            var cat = (_a = this.data) === null || _a === void 0 ? void 0 : _a.options.xaxis.categories;
            return {
                chart: {
                    fontFamily: "inherit",
                    type: "area",
                    height: 450,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {},
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: "solid",
                    opacity: 1
                },
                stroke: {
                    curve: "smooth",
                    show: true,
                    width: 3,
                    colors: [baseColor]
                },
                xaxis: {
                    categories: cat,
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: labelColor,
                            fontSize: "12px"
                        }
                    },
                    crosshairs: {
                        position: "front",
                        stroke: {
                            color: baseColor,
                            width: 1,
                            dashArray: 3
                        }
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: labelColor,
                            fontSize: "12px"
                        }
                    }
                },
                states: {
                    normal: {
                        filter: {
                            type: "none",
                            value: 0
                        }
                    },
                    hover: {
                        filter: {
                            type: "none",
                            value: 0
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: "none",
                            value: 0
                        }
                    }
                },
                noData: {
                    text: "Loading..."
                },
                tooltip: {
                    style: {
                        fontSize: "12px"
                    },
                    y: {
                        formatter: function (val) {
                            return val;
                        }
                    }
                },
                colors: [lightColor],
                grid: {
                    borderColor: borderColor,
                    strokeDashArray: 4,
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
                markers: {
                    strokeColor: baseColor,
                    strokeWidth: 3
                }
            };
        },
        series: function () {
            var _a;
            var res = (_a = this.data) === null || _a === void 0 ? void 0 : _a.series[0].data;
            return {
                series: [
                    {
                        name: "New Blocks",
                        data: res
                    }
                ]
            };
        }
    },
    mounted: function () {
        var _this = this;
        var delay = 600;
        setTimeout(function () {
            _this.$emit("updateChart");
        }, delay);
    }
});
