<template>
  <div class="container">
    <div class="row">
      <Chart
        widget-classes="card-xl-stretch mb-xl-8"
        :data="chartData"
        @update-chart="updateValues"
      ></Chart>
      <!-- <Chart
        widget-classes="card-xl-stretch mb-xl-8"
        :data="chartData"
        @update-chart="updateValues"
      ></Chart> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Chart from "./ChartWidget.vue";
import store from "@/store";

export default defineComponent({
  name: "stats",
  components: {
    Chart
  },
  setup() {
    const page = "1";
    localStorage.setItem("currentPage", page);
    const currentPage = localStorage.getItem("currentPage");
    store.dispatch("fetchStats", currentPage);

    onMounted(() => {
      setCurrentPageTitle("Stats");
    });
  },
  created() {
    const delay = 600;
    setTimeout(() => {
      const statsInfo = store.getters.statsInfo;

      const dates = this.getDatesReversed(statsInfo);
      const blocks = this.getNumbersOfBlocksReversed(statsInfo);

      this.chartData.options.xaxis.categories = dates;
      this.chartData.series[0].data = blocks;
    }, delay);
  },
  data() {
    const options = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: 450,
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: [""]
      },
      noData: {
        text: "Loading..."
      }
    };

    const series = [
      {
        name: "New Blocks",
        data: [0, 0, 0, 0, 0, 0, 0]
      }
    ];

    return {
      chartData: {
        options,
        series
      }
    };
  },

  methods: {
    updateValues() {
      //
    },
    getDatesReversed(dataObj) {
      const res: Array<string> = [];

      for (const i in dataObj) {
        const item = dataObj[i].new_date as string;
        // console.log(item);
        res.push(item);
      }

      const dates = res.reverse();
      return dates;
    },
    getNumbersOfBlocksReversed(dataObj) {
      const res: Array<number> = [];

      for (const i in dataObj) {
        const item = dataObj[i].blocks as number;
        // console.log(item);
        res.push(item);
      }

      const blocks = res.reverse();
      return blocks;
    }
  }
});
</script>
